<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">物候监测</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="县" prop="COUNTY">
                    <el-input
                        v-model="formdata.dataDic.COUNTY"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="乡" prop="COUNTRY">
                    <el-input
                        v-model="formdata.dataDic.COUNTRY"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="村" prop="VILLAGE">
                    <el-input
                        v-model="formdata.dataDic.VILLAGE"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="物种名称" prop="WZMC">
                    <el-input
                        v-model="formdata.dataDic.WZMC"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="监测点位" prop="JCDW">
                    <el-input
                        v-model="formdata.dataDic.JCDW"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="经度" prop="JD">
                    <el-input
                        v-model="formdata.dataDic.JD"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="纬度" prop="WD">
                    <el-input
                        v-model="formdata.dataDic.WD"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="监测人员" prop="JCRY">
                    <el-input
                        v-model="formdata.dataDic.JCRY"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="叶芽开放期" prop="FYQ_YYKFQ">
                    <el-input
                        v-model="formdata.dataDic.FYQ_YYKFQ"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="花芽开放期" prop="FYQ_HYKFQ">
                    <el-input
                        v-model="formdata.dataDic.FYQ_HYKFQ"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="开始展叶期" prop="ZYQ_KSZYQ">
                    <el-input
                        v-model="formdata.dataDic.ZYQ_KSZYQ"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="展业盛期" prop="ZYQ_ZYSQ">
                    <el-input
                        v-model="formdata.dataDic.ZYQ_ZYSQ"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="开花始期" prop="KHQ_KHSQ">
                    <el-input
                        v-model="formdata.dataDic.KHQ_KHSQ"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="开花盛期" prop="KHQ_KHSEQ">
                    <el-input
                        v-model="formdata.dataDic.KHQ_KHSEQ"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="开花末期" prop="KHQ_KHMQ">
                    <el-input
                        v-model="formdata.dataDic.KHQ_KHMQ"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="果实初期" prop="GQ_GSCQ">
                    <el-input
                        v-model="formdata.dataDic.GQ_GSCQ"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="果实成熟期" prop="GQ_GSCSQ">
                    <el-input
                        v-model="formdata.dataDic.GQ_GSCSQ"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="果实脱落期" prop="GQ_GSTLQ">
                    <el-input
                        v-model="formdata.dataDic.GQ_GSTLQ"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="叶变色期" prop="LYQ_YBSQ">
                    <el-input
                        v-model="formdata.dataDic.LYQ_YBSQ"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="落叶开始期" prop="LYQ_LYKSQ">
                    <el-input
                        v-model="formdata.dataDic.LYQ_LYKSQ"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="落叶末期" prop="LYQ_LYMQ">
                    <el-input
                        v-model="formdata.dataDic.LYQ_LYMQ"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="休眠开始期" prop="XMQ_XMKSQ">
                    <el-input
                        v-model="formdata.dataDic.XMQ_XMKSQ"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="休眠结束期" prop="XMQ_XMJSQ">
                    <el-input
                        v-model="formdata.dataDic.XMQ_XMJSQ"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="全部生长日数" prop="QBSZRS">
                    <el-input
                        v-model="formdata.dataDic.QBSZRS"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../../0_com_js/info_mixin.js";
export default {
    name: "",
    mixins: [infoMixin],
    data() {
        return {
            title: "",
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "8020516",
                dataDic: {
                    COUNTY: "",
                    COUNTRY: "",
                    VILLAGE: "",
                    WZMC: "",
                    JCDW: "",
                    JD: "",
                    WD: "",
                    JCRY: "",
                    FYQ_YYKFQ: "",
                    FYQ_HYKFQ: "",
                    ZYQ_KSZYQ: "",
                    ZYQ_ZYSQ: "",
                    KHQ_KHSQ: "",
                    KHQ_KHSEQ: "",
                    KHQ_KHMQ: "",
                    GQ_GSCQ: "",
                    GQ_GSCSQ: "",
                    GQ_GSTLQ: "",
                    LYQ_YBSQ: "",
                    LYQ_LYKSQ: "",
                    LYQ_LYMQ: "",
                    XMQ_XMKSQ: "",
                    XMQ_XMJSQ: "",
                    QBSZRS: "",
                },
            },
            SGLXList: [],
        };
    },
    mounted() {
        // this.GetEnum();
    },
    methods: {
        ...mapActions(["getAllEnumInfobytableID"]),
        async GetEnum() {
            // let res = await this.getAllEnumInfobytableID({
            //     bid: "1303",
            // });
            // let enumList = res || [];
            // enumList.forEach((v) => {
            //     if (v.COLUMNID === "JB") {
            //         this.SGLXList = v.dataItems;
            //     }
            // });
        },
    },
};
</script>

<style>
.yearBox .yearItem {
    width: 48% !important;
}
.yearBox .separate {
    padding: 0 10px;
}
</style>
